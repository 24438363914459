body
  margin: 0
  font-family: Roboto, "Helvetica Neue", sans-serif

.form-container
  max-width: 450px

.form-content
  max-width: 95%

.qr-code-scanner
  width: 100%

  >video
    width: 325px !important

  @media (width > 225px)
    video
      width: 100% !important

/* Bootstrap Style Overrides */
.card
  width: 375px

/* Google Captcha Style Overrides */
.grecaptcha-badge
  // bottom: 30% !important
  visibility: hidden !important

/* Material Design Overrides */
.cdk-global-overlay-wrapper
  height: 92% !important

/* Stepper Overrides */
.mat-step
  .mat-vertical-content
    padding-bottom: 0
    padding-right: 0
